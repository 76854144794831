<template>
  <v-card :loading="loading" outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("task details") }}</v-toolbar-title>
      <v-spacer />
      <v-tooltip
        top
        v-if="
          details != null &&
          details.extra != null &&
          details.extra.product_id != null
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: details.extra.product_id },
              })
            "
            :disabled="loading"
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("product") }}</span>
      </v-tooltip>
      <v-tooltip
        top
        v-if="
          details != null &&
          details.extra != null &&
          details.extra.order_number != null
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="dialogOrderStatus = true"
            :disabled="loading"
            icon
          >
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("order status") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="dialogCancel = true"
            :loading="loadingCancel"
            :disabled="loading || !canCancel"
            icon
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("cancel task") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="loading"
            :loading="loading"
            @click="getHtml()"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
      <wiki slug="tasks-details" />
    </v-toolbar>

    <v-dialog v-if="dialogCancel" v-model="dialogCancel" max-width="360">
      <validation-observer ref="obs" v-slot="{ invalid, validated }">
        <v-card>
          <v-card-title class="headline">{{ $t("cancel task") }}?</v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="pa-4">
            {{ $t("cancel-message") }}
          </v-card-subtitle>
          <v-card-text>
            <validation-provider
              vid="comment"
              :name="$t('cancel reason')"
              rules="required"
              v-slot="{ errors, valid, classes }"
            >
              <v-textarea
                name="comment"
                v-model="reason"
                :error-messages="errors"
                :success="valid"
                :label="$t('cancel reason')"
                :class="classes"
                counter="255"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="dialogCancel = false"
              :disabled="loadingCancel"
            >
              {{ $t("no") }}
            </v-btn>
            <v-btn
              outlined
              @click="cancelTask"
              :disabled="loadingCancel || invalid || !validated"
            >
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
    <v-dialog
      v-if="dialogOrderStatus"
      v-model="dialogOrderStatus"
      max-width="80%"
    >
      <bbcs-order-status
        :order-number="details.extra.order_number"
        @closeOrderStatus="dialogOrderStatus = false"
      />
    </v-dialog>
    <v-card-text v-if="!loading" v-html="html" class="ma-4" />
  </v-card>
</template>

<script>
import BbcsOrderStatus from "@/components/orders/BbcsOrderStatus";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "TaskDetails",
  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
  },
  mixins: [showErrors, isMobile],
  components: {
    BbcsOrderStatus,
    Wiki,
  },
  data: () => ({
    loadingHtml: false,
    loadingDetails: false,
    loadingCancel: false,
    html: null,
    details: null,
    canceled: false,
    reason: null,
    dialogCancel: false,
    dialogOrderStatus: false,
  }),
  watch: {
    taskId() {
      this.getHtml();
      this.getDetails();
    },
  },
  computed: {
    loading() {
      return this.loadingHtml || this.loadingDetails || this.loadingCancel;
    },
    canCancel() {
      return (
        !this.canceled &&
        !this.loading &&
        this.details != null &&
        this.details.can_cancel == true
      );
    },
  },
  methods: {
    getHtml() {
      if (this.taskId != null) {
        var that = this;
        this.loadingHtml = true;
        this.$http
          .get("workers/" + this.taskId + "/html", {
            headers: { accept: "text/html" },
          })
          .then((response) => {
            that.html = response.data;
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.loadingHtml = false;
          });
      }
    },
    getDetails() {
      if (this.taskId != null) {
        var that = this;
        this.loadingDetails = true;
        this.$http
          .get("workers/" + this.taskId)
          .then((response) => {
            that.details = response.data;
            that.canceled = response.data.canceled !== null;
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.loadingDetails = false;
          });
      }
    },
    cancelTask() {
      this.loadingCancel = true;
      var that = this;
      this.$http
        .post("workers/" + this.taskId + "/cancel", {
          comment: this.reason,
        })
        .then((response) => {
          that.canceled = true;
          that.getHtml();
          that.getDetails();
          that.dialogCancel = false;
          that.reason = null;
          that.$snotify.success(
            that.$i18n.t("MsgCancelSuccess", {
              id: that.taskId,
            })
          );
        })
        .catch(function (error) {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loadingCancel = false;
        });
    },
  },
  mounted() {
    this.getHtml();
    this.getDetails();
  },
};
</script>

<i18n>
{
  "en": {
    "task details": "Task details",
    "cancel task": "Cancel task",
    "cancel reason": "Cancel reason",
    "yes": "Yes",
    "no": "No",
    "MsgCancelSuccess": "Task {id} has been successfully canceled. Please note that it may take a few minutes for the order to be completely cancelled!",
    "cancel-message": "Please note that it may take a few minutes for the order to be completely cancelled!",
    "order status": "order status",
    "product": "Product",
    "reload": "reload"
  },
  "de": {
    "task details": "Auftragsdetails",
    "cancel task": "Auftrag abbrechen",
    "cancel reason": "Grund des Abbruchs",
    "yes": "Ja",
    "no": "Nein",
    "MsgCancelSuccess": "Auftrag {id} wurde erfolgreich abgebrochen. Bitte beachten Sie, daß es einige Minuten dauern kann, bis der Auftrag vollständig abgebrochen ist!",
    "cancel-message": "Bitte beachten Sie, daß es einige Minuten dauern kann, bis der Auftrag vollständig abgebrochen ist!",
    "order status": "Bestellstatus",
    "product": "Produkt",
    "reload": "aktualisieren"
  }
}
</i18n>

<style>
/**
 TODO: fix backend ticket template after frontend migration !!!
 */

ul {
  margin-top: 10px;
  margin-bottom: 15px;
}

.page-header small::before {
  content: "\2022";
  display: inline-block;
  padding-right: 10px;
}

.page-header small::after {
  content: "\A";
  white-space: pre;
}

.page-header small {
  font-size: 0.88rem;
  font-weight: bold;
}

.ticket-details div {
  padding: 20px;
  border-radius: 5px;
}

.theme--dark .ticket-details div {
  background: rgba(68, 66, 66, 0.5);
  box-shadow: 1px 1px 1px 1px rgba(54, 53, 53, 0.2);
}

.theme--dark .ticket-details {
  border-color: rgb(82, 79, 79);
  box-shadow: 1px 1px 1px 1px rgba(75, 74, 74, 0.2);
}

.theme--dark .ticket-details-info {
  border-left-color: #3c8dbc;
}

.theme--dark .ticket-details-danger {
  border-left-color: #ffab00;
}

.theme--light .ticket-details div {
  background: rgba(209, 209, 209, 0.5);
  box-shadow: 1px 1px 1px 1px rgba(221, 219, 219, 0.2);
}

.theme--light .ticket-details {
  border-color: rgb(224, 223, 223);
  box-shadow: 1px 1px 1px 1px rgba(221, 219, 219, 0.2);
}

.theme--light .ticket-details-info {
  border-left-color: #3c8dbc;
}

.theme--light .ticket-details-danger {
  border-left-color: #ffab00;
}

/**
 * from magic-customer-frontend-alex/css/core/tickets.css
 */

.page-title {
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.page-header {
  text-align: left;
}

.panel {
  border: none !important;
}

.panel-heading {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.row-panel {
  width: 100%;
  margin-left: 0;
}

.ticket-details {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-image: none;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px 1px 1px 5px;
  margin-bottom: 20px;
  padding: 20px;
}

.ticket-details:last-child {
  margin-bottom: 0;
}

.ticket-details h4 {
  margin-bottom: 10px;
  margin-top: 0;
}

.ticket-details-info h4 {
  color: #3c8dbc;
}

.ticket-details-danger h4 {
  color: #ffab00;
}

.ticket-details h2 {
  margin-top: 0;
}

.ticket-row {
  width: 100%;
}

.tickets .list-group-item:first-child,
.tickets .list-group-item:last-child {
  border-radius: 0;
}

.col-sm-8 {
  max-width: 100%;
}
</style>
